//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { imgChange } from "@/commons";
export default {
  components: {
    Header: () => import("@/components/Header"),
  },
  data() {
    var validateQQ = (rule, value, callback) => {
      if (value && !/^\d{5,11}$/.test(value)) {
        return callback(new Error("请输入正确的QQ号码"));
      }
      callback();
    };
    var validateTel = (rule, value, callback) => {
      if (value && !/^1(3|4|5|7|8)\d{9}$/.test(value)) {
        return callback(new Error("请输入正确的手机号码"));
      }
      callback();
    };
    return {
      nowTeam: null, //当前团队

      user: null,
      loading: false,
      isEdit: false,
      form: {},
      btnLoading: false,
      RULES: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        qq: [{ validator: validateQQ, trigger: "blur" }],
        phone: [{ validator: validateTel, trigger: "blur" }],
        email: [
          { type: "email", message: "请输入正确的邮箱地址", trigger: "blur" },
        ],
      },
      // 头像剪切参数
      dialogVisible: false,
      cropperOption: {
        img: "", // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 0.8, // 裁剪生成图片的质量
        outputType: "jpeg", // 裁剪生成图片的格式
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        fixedBox: true, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [100, 100], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: false, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      depList: [],
      memberList: [],
    };
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    imgChange,

    editImg(val) {
      if (val && val.includes("/images/head/")) {
        return this.$url + val;
      } else {
        return val;
      }
    },

    /**
     * 修改
     */
    handleEdit() {
      this.form = JSON.parse(JSON.stringify(this.user));
      if (this.form.Name) {
        this.getDefaultHead(this.form.Name);
      }
      this.isEdit = true;
    },
    /**
     * 获取用户信息
     */
    getUserInfo() {
      this.loading = true;
      this.$http
        .get("/Management/UserManagement/GetUserDetail.ashx", {
          params: { usId: null },
        })
        .then((resp) => {
          if (resp.res == 0) {
            this.user = resp.data;
          }
        })
        .finally(() => (this.loading = false));
    },

    //取消
    cancelEdit() {
      this.isEdit = false;
      this.form = Object.assign({}, this.user);
    },
    //提交
    comfir() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.btnLoading = true;
          this.form.Id = this.form.UsId;
          if (this.form.Picture.includes("/images/head/")) {
            this.form.Picture = null;
          }
          let resp = await this.$http.post("/User/EditUser.ashx", this.form);
          if (resp.res == 0) {
            if (this.form.UseName != this.user.UseName) {
              this.$notify({
                type: "warning",
                message: "您的账户已过期，请重新登录！",
              });
              this.$xStorage.removeItem("token");
              this.$xStorage.removeItem("user-role");
              this.$router.push("/");
            } else {
              this.$notify({
                type: "success",
                message: resp.msg,
              });
              this.getUserInfo();
              this.isEdit = false;
            }
          }
          this.btnLoading = false;
        }
      });
    },
    useDefalut() {
      if (this.form.Name) {
        this.getDefaultHead(this.form.Name);
      } else {
        this.$message.warning("请先输入昵称！");
      }
    },
    // 上传按钮单机后上传头像 限制图片大小
    changeUpload(file, fileList) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.error("上传文件大小不能超过 5MB!");
        return false;
      }
      var reader = new FileReader();
      reader.readAsDataURL(file.raw);
      reader.onload = (e) => {
        this.cropperOption.img = e.target.result;
      };
      this.dialogVisible = true;
    },
    // 拿到裁剪后的图片
    finishCropper() {
      this.$refs.memberCropper.getCropData((data) => {
        this.cropperOption.img = data;
        this.form.Picture = data;
        this.dialogVisible = false;
      });
    },
    /* 设置默认头像 */
    getDefaultHead(name) {
      var pattern = new RegExp("[\u4E00-\u9FA5]+");
      if (pattern.test(name)) {
        this.form.Picture = this.$userHead(name.substr(name.length - 2, 2));
      }
      var pattern2 = new RegExp("[A-Za-z]+");
      if (pattern2.test(name)) {
        this.form.Picture = this.$userHead(name.substr(0, 2));
      }
    },
    async inTaskLoad() {
      let resp = await this.$http.post("/Person/InTaskLoad.ashx");
      if (resp.res == 0) {
        this.memberList = resp.data.InTaskUserId.map((element) => {
          return {
            UId: element.UsId,
            UName: element.Name,
            Picture: element.Picture,
          };
        });
        this.depList = resp.data.InTaskReachId;
      }
    },
    //获取成员
    getMember(result) {
      let inTaskUserId = [];
      result.forEach((element) => {
        inTaskUserId.push(element.UId);
      });
      let inTaskReachId = this.filterId(this.depList, "RId");
      this.inTaskAdd(inTaskReachId, inTaskUserId);
    },
    //提取id
    filterId(list, id) {
      let idList = [];
      list.forEach((element) => {
        idList.push(element[id]);
      });
      return idList;
    },
    async inTaskAdd(inTaskReachId, inTaskUserId) {
      let params = {
        inTaskReachId,
        inTaskUserId,
      };
      let resp = await this.$http.post("/Person/InTaskAdd.ashx", params);
      if (resp.res == 0) {
        this.$notify({
          type: "success",
          message: resp.msg,
        });
      }
    },
  },
};
